<template>
  <v-row no-gutters>
    <!-- Select import method -->
    <v-col cols="12" v-if="showImportTypes">
      <v-row>
        <v-col cols="12" class="recipients-area__title pb-12">
          How would you like to import your recipients?
          <br />
          <span class="recipients-area__subtitle">
            Not sure which method to use? see a <span class="green2 pointer" @click="showVideo = true"> 2 min video </span> that will explain it all
          </span>
        </v-col>
        <v-col cols="12">
          <common-radio v-model="importType" input-value="upload-file">
            <span class="recipient-radio__text">
              Upload file
            </span>

            <template #non-clickable>
              <span class="recipient-radio__subtext d-flex align-center">
                Upload a CSV or tab-delimited TXT file.

                <a href="https://corporategift.com/resources/sample_list.csv" download>
                  Download .csv template
                </a>

                <popper
                  :options="{
                    placement: 'right',
                    modifiers: { offset: { offset: '0, 5px' } },
                  }"
                >
                  <div
                    class="popper pa-4 text-left recipient-radio__subtext--normal"
                    style="min-width: 300px; max-width: 500px;"
                  >
                    {{ getTooltipTextBySlug('download_sample_file') }}
                  </div>
                  <div class="d-flex pointer" slot="reference">
                    <icons-question :height="20" :width="20" />
                  </div>
                </popper>
              </span>
            </template>
          </common-radio>
        </v-col>
        <v-col cols="12">
          <common-radio
            v-model="importType"
            input-value="copy-paste"
          >
            <span class="recipient-radio__text">
              Copy and paste
            </span>

            <template #non-clickable>
              <span class="recipient-radio__subtext">
                Paste your contact information from a spreadsheet or other document in each column separately
              </span>
            </template>
          </common-radio>
        </v-col>

        <v-col cols="12" class="pt-8">
          <common-button @click="continueUpload(true)" height="45">
            Continue to

            {{ importType === 'upload-file' ? 'Upload' : 'Copy & Paste' }}
          </common-button>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" v-else>
      <!-- Upload file area -->
      <template v-if="importType === 'upload-file' && !showOrganizeFile">
        <v-row>
          <v-col cols="12" class="recipients-area__title">
            Upload recipients info
          </v-col>

          <v-col cols="12">
            <cg-alert type="information" background-color="#FCEBA4">
              This product can be shipped to US recipients only!
            </cg-alert>
          </v-col>

          <v-col cols="12">
            <the-upload-csv v-model="selectedFile" />
          </v-col>

          <v-col cols="12" class="d-flex align-center">
            <a href="https://corporategift.com/resources/sample_list.csv" download>
              Download .csv template
            </a>

            <popper
              :options="{
                placement: 'right',
                modifiers: { offset: { offset: '0, 5px' } },
              }"
            >
              <div class="popper pa-4 text-left" style="min-width: 300px; max-width: 500px;">
                {{ getTooltipTextBySlug('download_sample_file') }}
              </div>
              <div class="d-flex pointer mx-2" slot="reference">
                <icons-question :height="22" :width="22" />
              </div>
            </popper>

            <span class="f14 gray3 font-italic">
              Do not change the sample file headers!
            </span>
          </v-col>

          <v-col cols="12">
            <common-button
              outlined
              height="45"
              @click="continueUpload(false)"
            >
              Back
            </common-button>

            <common-button
              height="45"
              class="ml-2"
              :disabled="!selectedFile"
              @click="showOrganizeTable(selectedFile)"
            >
              Continue to review recipients
            </common-button>
          </v-col>
        </v-row>
      </template>

      <template v-else-if="importType === 'copy-paste' && !showOrganizeFile">
        <v-row>
          <v-col cols="12" class="recipients-area__title">
            Copy and paste your contacts

            <cg-alert type="information" background-color="#FAFAFA" class="recipient-copy-paste__alert">
              Paste recipient info by table column.
              <span @click="showExplanatoryModal = true">See how</span><br />
              You may review and edit in the next step.
            </cg-alert>
          </v-col>

          <v-col cols="12">
            <cg-alert type="information" background-color="#FCEBA4">
              This product can be shipped to US recipients only!
            </cg-alert>
          </v-col>

          <v-col cols="12">
            <the-paste-recipients-table
              v-model="copyPasteRecipients"
              v-bind="_props"
              @go-back="showImportTypes = true"
              @organize-file="(data) => showOrganizeTable(data, false)"
            />
          </v-col>
        </v-row>
      </template>

      <template v-else>
        <the-recipients-table
          v-model="recipientTableData"
          v-bind="_props"
          :convert-data="convertTableData"
          :backToCopyPaste="importType === 'copy-paste'"
          @go-back="backFromOrganizeFile()"
          @showPreview="(data) => $emit('showPreview', data)"
        />
      </template>
    </v-col>

    <v-dialog v-model="showVideo" persistent content-class="elevation-0" width="840">
      <v-container>
        <v-row dense>
          <v-col cols="11" v-if="showVideo">
            <iframe
              height="500"
              width="746"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
              frameborder="0"
              src="https://www.loom.com/embed/cda353e0ffeb47169b15d5810a62a2e8"
              style="box-shadow: rgb(0 0 0 / 75%) 0px 13px 18px -12px; background: #fff"
            />
          </v-col>
          <v-col cols="1">
            <div class="d-flex pointer justify-end" @click="showVideo = false">
              <icons-close
                :width="30"
                :height="30"
                color="#000"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>

    <explanatory-gif-modal
      v-model="showExplanatoryModal"
      alt-text="Copy & paste recipients info"
      link-to-gif="/images/copy-paste-explanatory.gif"
    />
  </v-row>
</template>

<script>
import Popper from 'vue-popperjs'
import TheUploadCsv from './TheUploadCsv.vue'
import TheRecipientsTable from './TheRecipientsTable.vue'
import ExplanatoryGifModal from '../../ExplanatoryGifModal.vue'
import ThePasteRecipientsTable from './ThePasteRecipientsTable.vue'
import { CgAlert } from '@corporategift/design-system'

import getTooltipTextBySlug from '@/mixins/getTooltipTextBySlug'
import marketplaceUser from '@/components/myCampaign/panels/mixins/marketplaceUser'
import panelType from '@/components/myCampaign/panels/mixins/panelType'

export default {
  name: 'TheRecipientsAreaImport',
  components: {
    Popper,
    CgAlert,
    TheUploadCsv,
    TheRecipientsTable,
    ExplanatoryGifModal,
    ThePasteRecipientsTable
  },
  mixins: [
    panelType,
    marketplaceUser,
    getTooltipTextBySlug,
  ],
  data: () => ({
    showImportTypes: true,
    showOrganizeFile: false,
    importType: 'upload-file',
    selectedFile: null,
    copyPasteRecipients: [],
    recipientTableData: null,
    convertTableData: true,
    showExplanatoryModal: false,
    showVideo: false
  }),
  methods: {
    continueUpload (val = true) {
      this.$emit('hideNavigation', val)
      this.$emit('hideCloseButton', val)
      this.showImportTypes = !val
      this.showOrganizeFile = !val
    },
    showOrganizeTable (data, convert = true) {
      this.showOrganizeFile = true
      this.recipientTableData = data
      this.convertTableData = convert
    },
    backFromOrganizeFile () {
      this.showOrganizeFile = false
      this.recipientTableData = null
      this.convertTableData = true
    }
  }
}
</script>

<style lang="scss" scoped>
.recipient-radio {
  &__text {
    font-family: 'Lato-Bold', sans-serif !important;
    font-size: 15px;
    color: #000;
    line-height: 22px;
  }
  &__subtext {
    font-family: 'Lato-Italic', sans-serif !important;
    padding-left: 30px;
    font-style: italic;
    gap: 8px;
    font-size: 15px;
    line-height: 18px;
    color: #9f9f9f;

    &--normal {
      font-style: normal;
    }
  }
}

a {
  font-family: 'Lato-Regular', sans-serif !important;
  white-space: nowrap;
  cursor: pointer;
  font-size: 15px;
  line-height: 18px;
  color: #219358 !important;
}

.recipient-copy-paste__alert {
  margin-top: 10px;
  padding: 16px;

  &::v-deep > .cg-alert__content {
    font-family: 'Lato-Regular', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #222325;

    & span {
      cursor: pointer;
      color: #42B77A;
    }
  }
}
</style>
