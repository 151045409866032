<template>
  <div class="settings-panel-payment">
    <div class="settings-panel-payment__title">
      {{ paymentLabel }}
    </div>

    <payment-module
      :paymentMethod.sync="selectedPaymentMethod"
      :paymentData.sync="selectedPaymentData"
      :default-payment-method="selectedPaymentMethod"
      :hide-other-payment-methods="true"
      ref="paymentModule"
    />

    <template v-if="!loading">
      <div
        class="settings-panel-payment__fallback"
        v-if="shouldHaveFallbackPaymentMethod && fallbackPaymentMethod"
      >
        Fallback payment method from {{ fallbackPaymentText }}

        <a @click="changeFallbackMethod = true">Change</a>
      </div>

      <div
        class="settings-panel-payment__fallback settings-panel-payment__fallback--missing"
        v-if="shouldHaveFallbackPaymentMethod && !fallbackPaymentMethod"
      >
        Fallback payment requires credit card details

        <a @click="changeFallbackMethod = true">Add credit card</a>
      </div>

      <a class="mt-7" @click="showOtherPaymentMethods()">Other payment methods</a>
    </template>

    <other-payment-methods-panel
      v-model="changeFallbackMethod"
      :loading="isLoading"
      :availableMethods="fallbackPaymentMethods"
      :userSavedPaymentData="savedPaymentData"
      :paymentMethod.sync="selectedFallbackPaymentMethod"
      :paymentData.sync="selectedFallbackPaymentData"
    />
  </div>
</template>

<script>
import Api from '@/axios/api'

import PaymentType from '@/components/payment/paymentModule/utils/PaymentType'
import userIsUsingBlueSnap from '@/mixins/userPaymentGateway'

import PaymentModule from '@/components/payment/PaymentModule.vue'
import OtherPaymentMethodsPanel from '@/components/payment/paymentModule/ThePaymentModuleOtherPaymentsPanel.vue'

export default {
  name: 'SettingsPanelPayment',
  components: {
    PaymentModule,
    OtherPaymentMethodsPanel,
  },
  mixins: [userIsUsingBlueSnap],
  props: {
    shouldHaveFallbackMethod: {
      type: Boolean,
      required: false,
      default: false,
    },
    paymentMethod: {
      type: String,
      required: false,
      default: PaymentType.BE,
    },
    paymentData: {
      type: Object,
      required: false,
      default: null,
    },
    fallbackPaymentMethod: {
      type: String,
      required: false,
      default: null,
    },
    fallbackPaymentData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => ({
    changeFallbackMethod: false,
    isMounted: false,
    loading: false,
    userSavedPaymentData: {
      achs: [],
      creditCards: [],
    },
  }),
  computed: {
    fallbackPaymentMethods () {
      const { userIsUsingBlueSnap } = this

      if (userIsUsingBlueSnap) {
        return [
          PaymentType.CC_Bluesnap,
          PaymentType.ACH_Bluesnap,
        ]
      } else {
        return [
          PaymentType.CC,
          PaymentType.ACH,
        ]
      }
    },
    selectedPaymentMethod: {
      get () { return this.paymentMethod },
      set (val) { this.$emit('update:paymentMethod', val) },
    },
    selectedPaymentData: {
      get () { return this.paymentData },
      set (val) { this.$emit('update:paymentData', val) },
    },
    selectedFallbackPaymentMethod: {
      get () { return this.fallbackPaymentMethod },
      set (val) { this.$emit('update:fallbackPaymentMethod', val) },
    },
    selectedFallbackPaymentData: {
      get () { return this.fallbackPaymentData },
      set (val) { this.$emit('update:fallbackPaymentData', val) },
    },
    savedPaymentData () {
      const defaultPaymentData = { achs: [], creditCards: [] }

      if (!this.isMounted) { return defaultPaymentData }
      return this.$refs.paymentModule.userSavedPaymentData ?? defaultPaymentData
    },
    isLoading () {
      if (!this.isMounted) { return false }
      return this.$refs.paymentModule.loading ?? false
    },
    shouldHaveFallbackPaymentMethod () {
      return this.paymentMethod === PaymentType.BE && this.shouldHaveFallbackMethod
    },
    fallbackPaymentText () {
      const { loading, fallbackPaymentMethod, selectedFallbackPaymentData: { token } } = this
      const { creditCards, achs } = this.userSavedPaymentData

      if (loading) { return null }

      if (fallbackPaymentMethod === PaymentType.CC) {
        const selectedCard = creditCards?.find((card) => card.token === token) ?? creditCards?.at(0)

        return `${selectedCard?.cardType} ****${selectedCard?.cardLastFourDigits}`
      } else {
        const selectedACH = achs?.find((ach) => ach.token === token) ?? achs?.at(0)

        return `Account Number ****${selectedACH?.last4}`
      }
    },
    paymentLabel () {
      const { paymentMethod } = this

      let paymentName = 'Budget entity balance'

      if ([PaymentType.CC, PaymentType.CC_Bluesnap].includes(paymentMethod)) {
        paymentName = 'Credit card'
      }
      if ([PaymentType.ACH, PaymentType.ACH_Bluesnap].includes(paymentMethod)) {
        paymentName = 'Ach account'
      }

      return `Pay with ${paymentName}`
    },
  },
  mounted () {
    this.isMounted = true
    this.loadSavedCC()
  },
  methods: {
    loadSavedCC () {
      this.loading = true

      Api.get('/braintree/saved-sources')
        .then(({ ccSource, achSource }) => {
          this.userSavedPaymentData.creditCards = ccSource ?? [];
          this.userSavedPaymentData.achs = achSource ?? [];
        })
        .finally(() => (this.loading = false))
    },
    showOtherPaymentMethods () {
      this.$refs.paymentModule.showOtherPaymentMethods()
    },
  }
}
</script>

<style lang="scss" scoped>
.settings-panel-payment {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__title {
    font-family: 'Lato-Bold', sans-serif;
    font-size: 15px;
    line-height: 18px;
    color: #000;
  }

  &__fallback {
    font-family: 'Lato-Italic', sans-serif;
    font-size: 15px;
    line-height: 18px;
    color: #A1A1A1;

    &--missing {
      color: #FF5A60;
    }
  }

  a {
    font-family: 'Lato-Italic', sans-serif;
    font-size: 15px;
    line-height: 18px;
    text-decoration: none;
    color: #42B77A !important;
  }

  &::v-deep .cg-payment-module .cg-credits-container > label {
    display: none;
  }
}
</style>
